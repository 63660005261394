import React from "react";
import { projets } from "../constants/constants";
import ImgWithFallback from "./ImgWithFallback";

const Work = () => {
  return (
    <div name="work" className="w-full text-gray-300 py-80px lg:px-[70px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full md:h-full">
        <div className="pb-8" data-aos="fade-up" data-aos-duration="2000">
          <p className="text-3xl font-bold inline border-b-4 text-gray-300 border-[#48fdd0]">
            Projets
          </p>
          <p className="py-6">
            Mes derniers projets (Si le contenu ne charge pas, merci de
            patienter quelques secondes pour réveiller l'application Heroku).
          </p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {/* Grid Item */}
          {projets.map((projet) => (
            <div
              key={projet.titre}
              data-aos="fade-up"
              data-aos-duration="2500"
              className="bg-white text-black rounded overflow-hidden"
            >
              <ImgWithFallback
                src={projet.img}
                fallbackSrc={projet.fallback}
                alt={projet.titre}
                loading="lazy"
              />
              <div className="p-2">
                <h3 className="font-bold text-xl mb-2">{projet.titre}</h3>
                <p className="mb-2">{projet.description}</p>
                <div className="flex place-content-around py-3">
                  {projet.github && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={projet.github}
                      className="text-white font-bold bg-[#2d81ff] py-2 px-3 hover:scale-110 duration-700 hover:bg-white hover:text-[#2d81ff] border-2 border-[#2d81ff]"
                    >
                      Code
                    </a>
                  )}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={projet.demo}
                    className="text-white font-bold bg-[#2d81ff] py-2 px-3 hover:scale-110 duration-700 hover:bg-white hover:text-[#2d81ff] border-2 border-[#2d81ff]"
                  >
                    Demo
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
