import React from "react";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full flex justify-center items-center p-4 py-80px h-fit sm:h-screen lg:h-fit 2xl:h-screen smallMobiles:h-fit"
    >
      <form
        data-aos="fade-up"
        data-aos-duration="2000"
        method="POST"
        action="https://formsubmit.co/d6d2b0022452ce90e788b8d146509b97"
        className="flex flex-col max-w-[600px] w-full"
      >
        <div className="pb-8">
          <p className="text-3xl pb-1 font-bold inline border-b-4 border-[#48fdd0] text-gray-300">
            Contact
          </p>
          <p className="text-gray-300 py-4">
            Vous pouvez me contacter via le formulaire ci-dessous ou à mon
            adresse quentin.cuoc@outlook.fr.
          </p>
        </div>
        <input
          className="font-semibold text-black mb-6 px-3 py-2 bg-white border-2 placeholder-slate-400 focus:outline-none focus:border-[#48fdd0] focus:ring-[#48fdd0] block w-full sm:text-sm focus:ring-8 duration-700 focus:rounded sm:focus:scale-110"
          type="text"
          placeholder="Nom"
          name="name"
          required
        />
        <input
          className="font-semibold text-black mb-8 px-3 py-2 bg-white border-2 placeholder-slate-400 focus:outline-none focus:border-[#48fdd0] focus:ring-[#48fdd0] block w-full sm:text-sm focus:ring-8 duration-700 focus:rounded sm:focus:scale-110"
          type="email"
          placeholder="Email"
          name="email"
          required
        />
        <textarea
          className="font-semibold text-black px-3 py-2 bg-white border-2 placeholder-slate-400 focus:outline-none focus:border-[#48fdd0] focus:ring-[#48fdd0] block w-full sm:text-sm focus:ring-8 duration-700 focus:rounded sm:focus:scale-110"
          name="message"
          rows="10"
          placeholder="Message"
          required
        ></textarea>
        <button
          type="submit"
          className="text-[#48fdd0] border-2 border-[#48fdd0]  px-4 py-3 my-8 mx-auto flex items-center hover:bg-[#48fdd0] hover:text-white transition ease-in-out duration-700"
        >
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default Contact;
