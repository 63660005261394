import React, { useState } from "react";
import { FaBars, FaTimes, FaGithub } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/logo.png";
import { Link } from "react-scroll";
import CV from "../assets/CV.pdf";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="backdrop-blur fixed w-full h-[80px] bg-[#0a192faf] flex justify-between items-center px-4 z-40">
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <Link to="home" smooth={true} duration={500}>
          <img
            src={Logo}
            alt="Logo"
            className="w-[50px] h-[50px] cursor-pointer"
          />
        </Link>
      </div>

      {/* menu */}
      <ul className="hidden md:flex">
        <li
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <Link
            to="home"
            smooth={true}
            duration={500}
            className="hover:text-[#48fdd0]"
          >
            Home
          </Link>
        </li>
        <li
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="150"
        >
          <Link
            to="about"
            smooth={true}
            duration={500}
            className="hover:text-[#48fdd0]"
          >
            À propos
          </Link>
        </li>
        <li
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <Link
            to="skills"
            smooth={true}
            duration={500}
            className="hover:text-[#48fdd0]"
          >
            Skills
          </Link>
        </li>
        <li
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          <Link
            to="work"
            smooth={true}
            duration={500}
            className="hover:text-[#48fdd0]"
          >
            Projets
          </Link>
        </li>
        <li
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="hover:text-[#48fdd0]"
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-40">
        {!nav ? (
          <FaBars className="text-2xl" />
        ) : (
          <FaTimes className="text-2xl" />
        )}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "fixed top-0 -left-full w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center transition-all"
            : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center transition-all duration-700"
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            À propos
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="work" smooth={true} duration={500}>
            Projets
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Social icons */}
      <div className="hidden lg:flex fixed flex-col top-[35vh] left-0">
        <ul>
          <div
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-easing="ease"
            data-aos-duration="1250"
          >
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
              <a
                className="flex justify-between items-center w-full text-gray-300"
                href="https://github.com/Quentin7722"
                target="_blank"
                rel="noreferrer"
              >
                Github <FaGithub size={30} />
              </a>
            </li>
          </div>
          <div
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-easing="ease"
            data-aos-duration="1250"
            data-aos-delay="150"
          >
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
              <a
                className="flex justify-between items-center w-full text-gray-300"
                href="mailto: quentin.cuoc@outlook.fr"
              >
                Email <HiOutlineMail size={30} />
              </a>
            </li>
          </div>
          <div
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-easing="ease"
            data-aos-duration="1250"
            data-aos-delay="250"
          >
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#1781fa]">
              <a
                className="flex justify-between items-center w-full text-gray-300"
                href={CV}
                target="_blank"
                rel="noreferrer"
              >
                CV <BsFillPersonLinesFill size={30} />
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
