import React from "react";
import HTML from "../assets/html.webp";
import CSS from "../assets/css.webp";
import JS from "../assets/javascript.webp";
import ReactImg from "../assets/react.webp";
import Node from "../assets/node.webp";
import Github from "../assets/github.webp";
import Tailwind from "../assets/tailwind.webp";
import Mongo from "../assets/mongo.webp";
import HTMLPng from "../assets/html-min.png";
import CSSPng from "../assets/css-min.png";
import JSPng from "../assets/javascript-min.png";
import ReactImgPng from "../assets/react-min.png";
import NodePng from "../assets/node-min.png";
import GithubPng from "../assets/github-min.png";
import TailwindPng from "../assets/tailwind-min.png";
import MongoPng from "../assets/mongo-min.png";
import ImgWithFallback from "./ImgWithFallback";

const Skills = () => {
  return (
    <div
      name="skills"
      className="w-full text-gray-300  py-80px lg:px-[70px] md:h-screen lg:h-fit largeDesktop:h-screen "
    >
      {/* Container */}
      <div
        className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div>
          <p className="text-3xl pb-1 font-bold inline border-b-4 border-[#48fdd0]">
            Skills
          </p>
          <p className="my-5">Quelques technologies familières.</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          <div className="cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={HTML}
              fallbackSrc={HTMLPng}
              alt="le logo HTML"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">HTML</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={CSS}
              fallbackSrc={CSSPng}
              alt="le logo CSS"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">CSS</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={JS}
              fallbackSrc={JSPng}
              alt="le logo de javascript"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">JAVASCRIPT</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={ReactImg}
              fallbackSrc={ReactImgPng}
              alt="le logo de la bibliothèque React"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">REACT</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={Github}
              fallbackSrc={GithubPng}
              alt="le logo de la plateforme Github"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">GITHUB</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={Node}
              fallbackSrc={NodePng}
              alt="le logo du runtime Node js"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">NODE JS</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={Mongo}
              fallbackSrc={MongoPng}
              alt="le logo HTML"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">MONGO DB</p>
          </div>
          <div className=" cursor-pointer group hover:scale-110 duration-500 py-5 bg-slate-700">
            <ImgWithFallback
              src={Tailwind}
              fallbackSrc={TailwindPng}
              alt="le logo HTML"
              className="w-20 h-20 mx-auto group-hover:animate-spin-slow"
              loading="lazy"
            />
            <p className="mt-5">TAILWIND CSS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
