import React from "react";
import { Suspense } from "react";
import { RiArrowDownCircleFill } from "react-icons/ri";
import Link from "react-scroll/modules/components/Link";
import { Canvas } from "@react-three/fiber";
import ModelDraco from "./ModelDraco";
import { OrbitControls } from "@react-three/drei";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen">
      {/* Container */}
      <div className="2xl:max-w-[1200px] mx-auto xl:ml-[180px] xl:mr-[50px] 2xl:mx-auto px-5  grid xl:grid-cols-2 items-center h-full pt-[100px]">
        <div className="flex flex-col text-center xl:text-left ">
          <p className="text-[#48fdd0] my-2">Bienvenue, je m'appelle</p>
          <h1 className="text-4xl whitespace-pre font-bold md:text-5xl lg:text-5xl largeDesktop:text-6xl animate-lineUp md:mt-3">
            Quentin Cuoc {"\n"}Je suis{"\n"}Développeur Web
          </h1>
          <div className="flex justify-center xl:justify-start md:mt-3">
            <Link
              to="work"
              smooth={true}
              duration={500}
              className="group cursor-pointer w-fit text-[#48fdd0] border-[#48fdd0] border-2 px-6 py-3 my-5 flex items-center hover:bg-[#48fdd0] hover:text-white transition ease-in-out duration-700 animate-lineUp"
            >
              Mon Portofolio
              <span className="group-hover:animate-bounce">
                <RiArrowDownCircleFill className="ml-5 text-xl" />
              </span>
            </Link>
          </div>
        </div>
        <div className="flex justify-center 2xl:justify-end items-center h-full transition ease-in-out duration-700 animate-lineUp">
          <Canvas className="canvas">
            <OrbitControls enableZoom={false} enablePan={false} />
            <ambientLight intensity={0.5} />
            <directionalLight position={[-2, 5, 2]} />
            <Suspense fallback={null}>
              <ModelDraco />
            </Suspense>
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default Home;
