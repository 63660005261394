import eCommerce from "../assets/e-commerce.webp";
import pong from "../assets/Pong-min.png";
import artworkHub from "../assets/artwork.webp";
import sushis from "../assets/sushis.webp";
import hulu from "../assets/hulu.webp";
import quiz from "../assets/quiz.webp";
import lafabrique from "../assets/lafabrique.webp";
import eCommercePng from "../assets/e-commerce-min.png";
import artworkHubPng from "../assets/artwork-min.png";
import sushisPng from "../assets/sushis-min.png";
import huluPng from "../assets/hulu-min.png";
import quizPng from "../assets/quiz-min.png";
import lafabriquePng from "../assets/lafabrique-min.png";

export const projets = [
  {
    img: eCommerce,
    fallback: eCommercePng,
    titre: "Headsets Store",
    description:
      "Un site e-commerce construit avec React.js, Commerce.js, Stripe et Material UI.",
    github: "https://github.com/Quentin7722/E-commerce",
    demo: "https://my-headsets-store.netlify.app",
  },
  {
    img: artworkHub,
    fallback: artworkHubPng,
    titre: "Artworks Hub",
    description:
      "Une application (MERN) responsive créée avec MongoDB, Express.js, React.js (Redux), Node.js et multer pour partager des images.",
    github: "https://github.com/Quentin7722/MERN-App",
    demo: "https://artworks-hub.netlify.app",
  },
  {
    img: pong,
    fallback: pong,
    titre: "Pong",
    description:
      "  Un clone du jeu Pong sur Atari créé dans le naviguateur avec Javascript et ses classes ES6.",
      github: "https://github.com/Quentin7722/Pong",
      demo: "https://quentin7722.github.io/Pong/",
  },
  {
    img: sushis,
    fallback: sushisPng,
    titre: "Tokyo Sushis",
    description:
      "Le site web d'un restaurant japonais créée avec la bibliothèque React.js, React Router et l'API Context.",
    github: "https://github.com/Quentin7722/Sushi",
    demo: "https://tokyo-sushi.netlify.app",
  },
  {
    img: hulu,
    fallback: huluPng,
    titre: "Hulu",
    description:
      "Un clone de la plateforme VOD Hulu créée avec les frameworks Next.js, Tailwind CSS, l’API Fetch et The Movie Database.",
    github: "https://github.com/Quentin7722/Nextjs-Hulu-Clone",
    demo: "https://nextjs-hulu-clone-c6im8yxkq-quentin7722.vercel.app/?genre=fetchTrending",
  },
  {
    img: quiz,
    fallback: quizPng,
    titre: "Quiz citations",
    description:
      "Une application mobile de quiz créée avec React Native et Android Studio.",
    demo: "https://play.google.com/store/apps/details?id=com.quentinc.citaquiz",
  },
  {
    img: lafabrique,
    fallback: lafabriquePng,
    titre: "La Fabrique",
    description:
      "Une landing page pour une entreprise d'artisanat construite avec Bootstrap V5.",
    github: "https://github.com/Quentin7722/Landing-Page-Bootstrap",
    demo: "https://quentin7722.github.io/Landing-Page-Bootstrap//",
  },
];
