import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full text-gray-300 z-0 smallMobiles:h-fit h-screen lg:px-[70px]"
    >
      <div className="flex flex-col justify-center items-center w-full h-full py-80px">
        <div data-aos="fade-up" data-aos-duration="2000">
          <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
            <div className="sm:text-right pb-8 pl-4">
              <p className="text-3xl pb-1 font-bold inline border-b-4 border-[#48fdd0]">
                À propos
              </p>
            </div>
            <div></div>
          </div>
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
            <div className="sm:text-right text-3xl font-bold">
              <p>
                Je m'appelle quentin et je suis développeur web full-stack
                junior.
              </p>
            </div>
            <div>
              <p>
                Après des études en langue étrangères appliquées, j'ai décidé de
                commencer un nouveau parcours professionnel pour devenir
                développeur. J'ai suivi une formation openclassrooms de niveau
                bac +2 dans laquelle j'ai appris à utiliser des outils tels que
                Bootstrap, Node.js, React.js, MySQL, MongoDB ainsi que la
                création de sites web responsives, accessibles et bien
                référencés. J'ai aussi travaillé à titre personnel avec des
                outils tels que Wordpress, Next.js, Sanity.io, PHP ou React
                Native.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
